@import url("./abstracts/mixins");
@import url("./abstracts/colors");
@import url("./base/layout-commons");
@import url("./base/spacing");
@import url("./base/style");
@import url("./base/typography");
@import url("./base/visibility-utils");
@import url("./base/yui-compatibility");
@import url("./form/checkbox");
@import url("./form/codemirror");
@import url("./form/input");
@import url("./form/layout");
@import url("./form/radio");
@import url("./form/reorderable-list");
@import url("./form/search-bar");
@import url("./form/select");
@import url("./form/toggle-switch");
@import url("./form/validation");
@import url("./modules/app-bar");
@import url("./modules/badges");
@import url("./modules/breadcrumbs");
@import url("./modules/buttons");
@import url("./modules/buttons-deprecated");
@import url("./modules/content-blocks");
@import url("./modules/icons");
@import url("./modules/notifications");
@import url("./modules/page-footer");
@import url("./modules/page-header");
@import url("./modules/panes-and-bigtable");
@import url("./modules/section");
@import url("./modules/side-panel-tasks");
@import url("./modules/side-panel-widgets");
@import url("./modules/spinner");
@import url("./modules/progress-animation");
@import url("./modules/row-selection-controller");
@import url("./modules/table");
@import url("./modules/tabs");
@import url("./modules/tooltips");
@import url("./pages/about");
@import url("./pages/dashboard");
@import url("./pages/icon-legend");
@import url("./pages/manage-jenkins");
@import url("./pages/plugin-manager");
@import url("./pages/setupWizardFirstUser");
@import url("./pages/setupWizardConfigureInstance");

html {
  // Ensure that the 1rem size is 16px and scales with the browser zoom
  // This is needed because bootstrap 3 overrides the font-size when loaded.
  //
  // It's set to 16px instead of 100% because using percentages would trigger
  // known browser bugs where elements with a font-family: monospace declaration
  // would not respect relative font-size rules.
  // The downside is that the page does not resize with the browser's font size,
  // only with the zoom level.
  font-size: 16px !important;
  letter-spacing: -0.016em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}
